.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #0C5E9D;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  padding-left: 78px;
  padding-right: 20px;
  background-size: 84px 24px;
  background-position: -20px 2px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-sitecolor.svg);
  transition: all 0.4s;
}
.button:hover,
.button:focus,
.area--one .unitTwo:hover .button,
.ns-teaser--news:hover .button {
  padding-right: 0;
  padding-left: 98px;
  background-position: 0 2px;
}
@media (max-width: 767px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
    background-size: 63px 18px;
    background-position: -13px 3px !important;
    padding-right: 0 !important;
    padding-left: 62px !important;
  }
}
.area--one .unitOne--1-2 {
  width: calc(50% - 30px);
}
.area--one .unitOne--1-3 {
  width: calc(50% - 30px);
}
.area--one .unitOne--1-5 {
  width: calc((100% / 3) - 30px);
}
.area--one .unit--globalForm {
  width: calc(50% - 30px);
}
.area--three {
  width: calc(100% + 40px);
  margin-left: -15px;
}
.area--three .unit {
  margin-left: 15px;
  margin-right: 15px;
  max-width: calc(20% - 30px);
}
.area--four {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--four .unit {
  margin-left: 15px;
  margin-right: 15px;
}
.area--four .unitOne--1-1 {
  width: calc(100% - 30px);
}
.area--four .unitOne--1-2 {
  width: calc(50% - 30px);
}
/*# sourceMappingURL=./screen-medium.css.map */